@import "../../../../style/mixin";

.header__nav {
  display: flex;
  align-items: center;
  gap: 28px;

  button {
    background: transparent;
    display: flex;
    height: fit-content;
    gap: 8px;

    &:hover {
      span {
        color: var(--white);
      }
      svg path {
        fill: var(--white);
      }
    }

    span {
      color: var(--landing-text-label);
      font-weight: 600;
    }
  }

  .promo {
    display: none;
  }

  @include b1800 {
    .promo {
      display: flex;
    }
  }

  @include l1580 {
    gap: 20px;
  }

  @include l1440 {
    gap: 12px;

    button svg {
      display: none;
    }
  }

  @include l1220 {
    gap: 8px;

    .promo {
      display: flex;
    }

    button {
      gap: 4px;

      svg {
        // width: 18px;
        // height: 18px;
        display: none;
      }
    }
  }

  @include smallDesktop {
    gap: 8px;

    button span {
      font-size: 12px;
    }
  }

  @include lsm {
    gap: 16px;

    button span {
      font-size: 16px;
    }
  }

  @include l670 {
    gap: 12px;

    button span {
      font-size: 12px;
    }
  }
}
