:root {
  // color
  --white: #fff;
  --black: #000;
  --green: #1fbf66;
  --green-2: #00cb2d;
  --green-3: #d0ff49;
  --green-4: #14ab55;
  --violet: #741ab5;
  --gray: #615968;
  --gray2: #94a4b0;
  --blue: #20a1ff;
  --blue2: #0b58d7;
  --yellow: #ffc120;
  --yellow-2: rgb(255, 193, 32);
  --orange: #ff562c;
  --red: rgb(228, 5, 57);
  --pink: #ff5454;
  --pink-violet: #da1ede;
  --red01: #ff54541a;

  // color-opacity
  --white-80: #ffffffbd;
  --green-80: #54ff5b14;
  --green-3-20: #d0ff4920;

  // var

  --shade07: #030d1cb2;
  --shade1: #101d30;
  --shade2: #1c2e49;
  --shade3: #2f4a72;
  --shade3-08: #2f4a7280;
  --shade4: #172944;
  --shade5: #5280a3;
  --shade7: #75a7cd;

  // btn
  --primary-btn-text: #ffffff;
  --primary-btn-disable: #213757;
  --primary-btn: #213e4f;
  --primary-btn-hover: #223756;
  --primary-btn-active: #223756;
  --primary-btn-disable: #1c2e49;

  --primary-btn-2-text: #fcfeff;
  --primary-btn-2-text-hover: #020304;
  --primary-btn-2-disable: #213757;
  --primary-btn-2: #8bac5c;
  --primary-btn-2-hover: #deff5b;
  --primary-btn-2-active: #dbff00;
  --primary-btn-2-disable: #d0ff49;

  --primary-btn-3-text: #fcfeff;
  --primary-btn-3-disable: #213757;
  --primary-btn-3: linear-gradient(163.35deg, var(--blue) 11.495%, var(--blue2) 62.724%);
  --primary-btn-3-hover: linear-gradient(163.35deg, var(--blue) 11.495%, var(--blue2) 62.724%);
  --primary-btn-3-active: linear-gradient(163.35deg, var(--blue) 11.495%, var(--blue2) 62.724%);
  --primary-btn-3-disable: linear-gradient(163.35deg, var(--blue) 11.495%, var(--blue2) 62.724%);

  --primary-select-bg: #101d30;
  --primary-select-border-bg: #0a2737;
  --primary-select-bg-hover: #1c2e49;
  --primary-select-bg-open: #101d30;
  --primary-select-bg-disabled: #101d30;

  --primary-search-bg: #172944;
  --primary-search-border-bg: #0a2737;
  --primary-search-bg-hover: #1c2e49;
  --primary-search-bg-active: #172944;
  --primary-search-bg-disabled: #101d30;
  --primary-search-bg-chosen: #101d30;
  --primary-search-bg-chosen-hover: #1c2e49;

  --default-box-shadow: 0px 4px 9.9px 0px rgba(0, 96, 255, 0.63),
    0px 7px 7.3px 0px rgba(0, 0, 0, 0.25);

  //landing
  --landing-background: #00141f;
  --landing-item-bg: #011d2d;
  --landing-item-bg-border: rgb(12, 49, 69);
  --landing-item-bg-2: #0a2737;

  --landing-text-label: #b7daec;
  --landing-text-label-hover: #cbdcee;
  --landing-text-label-disable: #213757;
  --landing-placeholder: #5f87a5;
  --landing-text-label-2: #75a7cd;
  --landing-text-label-opacity: #2f4a72;

  --menu-count-bg-hover: rgba(32, 161, 255, 0.08);
  --menu-count-bg-active: rgba(32, 161, 255, 0.12);
  --menu-count-text: #5280a3;
  --menu-count-text-hover: rgb(192, 211, 214);
  --segment-label: #0d2e35;

  --indicator-bg: #a6ff35;

  --jackpot-currency: #4a5b6c;
  --jackpot-card-title: #fcfeff99;

  --card-currency-green: #81ff94;
  --card-currency-blue: #79a6ff;
  --card-currency-violet: #d69eff;

  --game-item: #0a2737;
  --game-item-favorites: #101d30;

  --last-rates-table-title: #627d8c;
  --last-rates-btn-x: #2f4a72;
  --last-rates-win: #66ff4d;
  --last-rates-lose: #ff574d;

  --modal-bg: #030d1c;

  --checkbox-gradient: linear-gradient(
    163.35deg,
    rgb(46, 167, 255) -1.374%,
    rgb(0, 87, 255) 88.462%
  );
  --gradient-blue: linear-gradient(163.35deg, rgb(46, 167, 255) 16.18%, rgb(0, 87, 255) 53.355%);
  --gradient-purpure: linear-gradient(180deg, #a005ff 0%, #c15cff 100%);

  // components
  --login-bg: #030d1c;
  --success-check-bg: #54ff5b0d;
  --warning-check-bg: #ffd9540d;

  --bonus-first-bg: #ff48f8;
  --bonus-first-bg-tr: #ff5dc826;
  --bonus-first-btn: #ff5dc8;
  --bonus-caskback-bg: #48ff5b;
  --bonus-caskback-bg-tr: #605dff26;
  --bonus-caskback-btn: #605dff;
  --bonus-register-bg: #5748ff;
  --bonus-register-bg-tr: #5dff6326;
  --bonus-register-btn: #5dff63;
  --bonus-promo-bg: #48ffd3;
  --bonus-promo-bg-tr: #d0ff4926;
  --bonus-promo-btn: #d0ff49;
  --bonus-welcome-bg: #184465;
  --bonus-welcome-bg-tr: #329edcb4;
  --bonus-welcome-btn: #fdc320;

  --top-up-gradient: #f3eb2c;

  // other
  --facebook: #1877f2;
  --twitter: #1da1f2;
  --telegram: #20a1ff;
  --instagram: #f00073;
  --x: #000;
  --whatsapp: #128c7e;
}
