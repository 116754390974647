@import "../../../style/mixin";

.custom-segment {
  width: 100%;
  background-color: var(--primary-select-bg);
  border: 2px solid var(--primary-select-border-bg);
  border-radius: 16px;
  padding: 4px !important;

  .rc-segmented {
    &-item {
      border-radius: 12px;
      padding: 10px 8px;
      width: 100%;

      &-selected {
        background-color: var(--primary-btn-2-active);

        .rc-segmented-item-label {
          color: var(--segment-label);
        }
      }
    }

    &-thumb {
      background-color: var(--primary-btn-2-active);
      border-radius: 12px;
    }

    &-item-label {
      font-size: 14px;
      font-weight: 700;
      transition: 0.5s;
      color: var(--landing-text-label);
    }
  }

  @include l670 {
    .rc-segmented {
      &-item {
        padding: 10px 8px;
      }

      &-item-label {
        font-size: 12px;
        line-height: 120%;
      }
    }
  }
}
