@import "./currency-is-full-variant.scss";

.choose-currency {
  height: 100%;
  padding: 8px 12px;
  padding-right: 4px;
  background-color: var(--shade07);
  position: relative;
  min-width: 120px;
  z-index: 2;

  &__wrapper {
    overflow: hidden;
    position: relative;
    background-color: var(--shade3);
  }

  &__gradient {
    position: absolute;
    background: linear-gradient(90deg, var(--top-up-gradient), transparent);
    bottom: 0;
    left: -10%;
    width: 80%;
    height: 100%;
    z-index: 2;
  }

  &__arrow {
    width: 16px;
    height: 16px;
    margin-left: auto;
  }

  &-container {
    position: relative;
    z-index: 12;

    &.open {
      ul {
        display: flex;
      }

      .choose-currency__arrow {
        transform: rotate(180deg);

        path {
          stroke-opacity: 1;
          stroke: var(--green-3);
        }
      }
    }

    .balance-value {
      display: flex;
      flex-direction: column;
      gap: 2px;

      span {
        line-height: 12px;
      }

      .bonus {
        font-size: 12px;
        color: var(--landing-text-label);
      }
    }

    .currency {
      background-color: var(--shade4);
      border-radius: 6px;
      padding: 4px 8px;
      font-size: 14px;
      font-weight: 600;
      margin-left: auto;
    }
  }

  &__active {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--white);
    font-weight: 600;

    .currency {
      margin-left: 0;
    }
  }

  &__plug {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    background-color: transparent;
    z-index: 1;

    ul {
      display: none;
      margin-top: 52px;
      min-width: 100%;
      background-color: var(--shade1);
      border: 2px solid var(--shade2);
      border-radius: 12px;
      overflow: hidden;
      padding: 8px;
      flex-direction: column;
      gap: 2px;

      li button {
        width: 100%;
        border-radius: 12px;
        background-color: transparent;
        gap: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 8px 2px;

        & > .main {
          width: 100%;
        }

        .balance-value {
          margin-left: auto;
        }

        .main {
          display: flex;
          align-items: center;
          gap: 6px;
        }

        .bonus {
          font-size: 12px;
          color: var(--landing-text-label);
        }

        &.active {
          background-color: var(--shade2);
        }

        &:hover {
          background-color: var(--shade3);
        }
      }
    }
  }
}
