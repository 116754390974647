@import "../../../style/mixin";
@import "../../../style/template";

.header {
  @extend %borderLanding;
  width: 100%;
  padding: 2px 0;
  display: flex;
  justify-content: space-between;
  padding: 8px 0;

  .aside-menu {
    display: none;
  }

  &__logo__text {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    font-family: "Unbounded";
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .logo {
    width: auto;
  }

  .login-btn {
    margin-left: auto;
  }

  &__right {
    margin-left: auto;
    margin-bottom: auto;
    &__auth {
      display: flex;
      align-items: end;
      gap: 20px;
    }

    .user__nickname {
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .balance {
      margin-left: 32px;
    }

    .choose-currency {
      margin: 0 20px;
    }

    .user,
    &.tg {
      margin-left: auto;
    }

    &.tg {
      width: auto;
      .header__right {
        width: auto;
      }

      .balance {
        width: auto;

        &__item {
          label {
            font-size: 18px;
          }
        }
      }
    }
  }

  @include l1220 {
    padding: 16px 0;
    justify-content: start;
    gap: 10px;

    &__auth {
      margin-left: auto;
    }

    .aside-menu {
      display: flex;
    }

    .choose-currency {
      margin: 0 4px;
    }

    &.no-auth {
      .logo {
        width: auto;
        margin: 0;
      }
    }

    .logo {
      width: 100%;
    }

    .balance {
      width: auto;
    }
  }

  @include smallDesktop {
    &-nav-two {
      margin: 16px 0;
      nav {
        display: flex;
        justify-content: space-between;

        button {
          gap: 8px;
        }
      }
    }
  }

  @include lsm {
    .balance {
      margin-left: 12px;
    }

    &__right__auth {
      gap: 8px;
    }
  }

  @include l670 {
    padding: 8px 0;

    &__content {
      display: grid;
      grid-template-columns: auto auto;
    }

    &__right {
      .user__nickname {
        max-width: auto;
      }
    }

    .logo__text,
    .online__indicator,
    .header__nav {
      display: none;
    }

    #aside-absolute {
      width: auto;
      .logo {
        flex-direction: row-reverse;
        gap: 12px;
        margin: 0;
        padding: 0;
        background-color: transparent;
        align-items: center;
      }

      .aside-menu__menu {
        top: 56px;
      }
    }

    &-nav-two {
      display: none;
    }
  }
}
