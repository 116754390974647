@import "../../../style/mixin";

.start-tg-bot__modal {
  padding: 0;
  border-radius: 0;
  background-color: transparent;

  width: 820px;
  left: calc(50svw - 400px);
  top: 10svh;

  .modal__close {
    right: 8px;
    top: 8px;
  }

  img {
    width: 100%;
    max-height: 80svh;
    cursor: pointer;
  }

  @include lsm {
    width: 90svw;
    left: 5svw;
  }

  @include sm {
    width: 95svw;
    left: 2.5svw;
    top: 5svh;
    img {
      max-height: 90svh;
    }
  }
}
