@import "../../../style/mixin";

.default-modal.mini.promo-code {
  padding: 0;
}

.promo-code {
  padding: 4px;
  &.transparent {
    background-color: transparent;
    padding: 0;
  }

  &__success {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    gap: 20px;
    padding: 0 20px;

    h3 {
      font-size: 20px;
    }

    p {
      font-size: 20px;
      font-weight: 500;
      color: var(--landing-text-label);
    }
  }

  &__text__link {
    color: var(--telegram);
    font-weight: 600;
    opacity: 0.8;
    font-size: 20px;

    &:hover {
      color: var(--telegram);
      opacity: 1;
    }
  }

  &__img {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--modal-bg);
    background-image: url("./percent-bg-banner.png");
    border-radius: 16px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__info {
    background-color: var(--modal-bg);
    position: relative;
    padding: 20px;
    border-radius: 16px;

    p {
      margin-top: 8px;
      color: var(--landing-text-label-2);
      font-weight: 600;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  @include l670 {
    &__text__link {
      color: var(--telegram);
      font-size: 16px;
    }

    &__success {
      h3 {
        font-size: 16px;
      }
    }
  }
}
