@import "../../../../style/mixin";

.fortune-register {
  &.default-modal.mini {
    padding-top: 60px;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 20px;
  }

  &__currency {
    display: flex;
    margin-left: auto;

    .choose-currency__wrapper {
      border-radius: 12px;
    }
  }

  &__bonus {
    width: 80%;
    display: flex;
    margin: 8px auto;
  }

  .login-fields {
    margin-top: 12px;
    h2 {
      text-align: left;
    }

    .login__auth-tg {
      display: flex;
      justify-content: center;
      margin-top: 12px;
    }

    .login__segment {
      margin-top: 8px;
    }
  }

  .tg__title {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    gap: 20px;

    &__text {
      font-size: 12px;
      font-weight: 600;
      color: var(--menu-count-text);
    }

    &__left,
    &__right {
      height: 0.1px;
      width: 100%;
      background: var(--landing-text-label-2);
    }
  }

  .login__form {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  @include smallDesktop {
    h1 {
      font-size: 32px;
    }
  }

  @include ltb {
    h1 {
      font-size: 20px;
    }
  }
}
