@import "../../../../../style/template.scss";

.avatar-popup {
  position: relative;
  &__person {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    height: 48px;
    width: 200px;
    background-color: var(--shade2);
    border: 2px solid var(--shade3);
    border-radius: 12px;
    padding: 8px;
    text-overflow: ellipsis;

    &__name,
    &__auth {
      @extend %one-string;
    }

    &__auth {
      width: 100%;
      font-weight: 600;
    }

    img {
      height: 28px;
      width: 28px;
      border-radius: 4px;
    }

    .rotate180 path {
      stroke: var(--green-3);
      stroke-opacity: 1;
    }
  }

  &__popup {
    position: absolute;
    background-color: var(--landing-background);
    min-width: 100px;
    top: 52px;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    border-radius: 12px;
    z-index: 9;

    li {
      width: 100%;
      border-radius: 12px;
      background-color: var(--shade2);
      border: 2px solid var(--shade3);
      height: 44px;
      padding: 8px;

      button {
        background-color: transparent;
        width: 100%;
      }
    }
  }

  &.small-desktop {
    &.is-auth {
      .avatar-popup {
        &__person {
          padding: 0;
          border: none;
          width: 40px;
          height: 40px;
          background-color: var(--landing-background);
          padding: 0;
          border: none;
          border-radius: 6px;
          overflow: hidden;
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .avatar-popup {
      &__popup {
        li {
          border-radius: 8px;

          &.nickname {
            @extend %one-string;
            background-color: var(--landing-background);
            height: auto;
            display: flex;
            justify-content: center;
            padding: 4px 6px;
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
