@import "../../../../style/mixin";

.fortune-wheel {
  position: relative;
  height: 650px;
  width: 650px;
  max-width: 80svh;
  max-height: 80svh;

  .fortune-wheel__values > img {
    transform: rotate(45deg);
  }

  &.rotate {
    .fortune-wheel__values > img {
      // animation: rotateWheel 2s linear infinite normal;
      animation:
        rotateWheel 0.75s linear 1 normal forwards,
        rotateWheelWin 0.75s linear 1 normal forwards 0.75s;
    }
  }

  &.rotated {
    .fortune-wheel__values > img {
      transform: rotate(0);
    }
  }

  &__img-mask-border {
    position: relative;
    z-index: 2;
  }

  &__border {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__values {
    position: absolute;
    width: 80%;
    left: 10%;
    top: 10.5%;
  }

  &__btn {
    position: absolute;
    top: 0;
    width: 50%;
    left: 25%;
    top: 25%;
    background-color: transparent;
    cursor: pointer;
    z-index: 2;
    transition: 0.5s;

    &:hover {
      scale: 1.05;
      img {
        scale: 1.05;
      }
    }

    span {
      position: absolute;
      left: 20%;
      top: 20%;
      width: 60%;
      height: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      font-weight: 700;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
  }

  @include smallDesktop {
    width: 400px;
    height: 400px;

    &__btn span {
      font-size: 24px;
    }
  }

  @include lxs {
    width: 90svw;
    height: 90svw;

    &__btn span {
      font-size: 16px;
    }
  }

  @include smLsp {
    max-width: 60svh;
    max-height: 60svh;

    &__btn span {
      font-size: 16px;
    }
  }

  @keyframes rotateWheel {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(405deg);
    }
  }
  @keyframes rotateWheelWin {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
